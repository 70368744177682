import { useState, useEffect } from "react"
import { updateAuthorizationHeader } from "../../../shared/utility"
import { private_server, public_server, USER_TYPES } from "../../../config"
import { generic_errors } from "../../../texts/esp/genericErrors"
import queryString from 'query-string'

const useInfluencersView = () => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [managers, setManagers] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [selectedItem, setSelectedItem] = useState(null)
    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false,
        edit_membership_date:false
    })

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        actions.onInitModule()
    }, [filter, tableFilter])

    const onGetParams = () => {
        const {limit, offset} = tableFilter
        const {query} = filter

        let andFiltersArgs = [
            {"field":"user_type_id","operator":"=","value":6},
        ]

        if(query) andFiltersArgs.push({"field":"first_name","operator":"LIKE", "value":`%${query}%`})

        let finalArgs = {
            "AND":andFiltersArgs
        }

        const param2send = {limit:limit, offset:limit*offset, order_by:'id_user', order:1}
        const params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`


        return params
    }

    const orderByName = (a,b) => {
        if (a.first_name < b.first_name) return -1;
        if (a.first_name > b.first_name) return 1;
        return 0;
    }
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data

                // Request all managers
                let managerParmas = {"AND":[{"field":"user_type_id","operator":"=","value":USER_TYPES.MANAGER}]}

                const managers_request = await private_server.get(`/user/all?filter=${encodeURIComponent(JSON.stringify(managerParmas))}`)
                console.log('Managers', managers_request.data.data)
                const _managers = managers_request.data.data.users
                _managers.sort(orderByName)
                setManagers(_managers)
                temp.users = temp.users.map(r => ({...r, table_from: (tableFilter.offset * tableFilter.limit)}))
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateUsers: async() => {
            setSending(true)
            try {
                const _params = onGetParams()
                updateAuthorizationHeader(private_server)
                const users_request = await private_server.get(`/user/all${_params}`)
                const temp = users_request.data.data
                temp.users = temp.users.map(r => ({...r, table_from: (tableFilter.offset * tableFilter.limit)}))
                setTotal(temp.count)
                setData(temp.users)
            } catch (error) {
                console.log(error)
            }
            setSending(false)
        },
        onChangeSearch:(data) => {
            let temp = {...filter}
            temp.query = data
            setFilter(temp)
        },
        onChangeInfluencerStatuses:async(status, new_value, _id) => {
            let data2send = {}
            if(status === 'type') data2send={influencer_type_id: new_value} 
            if(status === 'size') data2send={influencer_size_id: new_value} 
            if(status === 'membership_type') data2send={membership_type_id: new_value}
            if(status === 'membership_status') data2send={membership_status_id: new_value}
            if(status === 'enabled') data2send={enabled: new_value} 
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${_id}`, data2send)
                actions.onInitModule()
            } catch (error) {
                console.log(error)
            }
        },
        onDeleteUser:async(_id, user_name) => {
            try {
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/user/${_id}`)
                actions.onInitModule()
                alert(`Se elimino el usuario: ${user_name}`)
            } catch (error) {
                alert('Hubo un error al eliminar el usuario')
            }
        },
        onEditMembershipDate: (item) =>{
            setSelectedItem(item)
            actions.onUpdateModalStatus('edit_membership_date', true)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (data) => setFilter(data)
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, filter, selectedItem, managers}

    return {system, actions, view_data, modals}
}

export default useInfluencersView