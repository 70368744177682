import { useEffect, useState } from "react"
import { private_server } from "..//../config"

const useResumeView = ({user}) => {
    const [loading, setLoading] = useState(false)

    const [metrics,setMetrics] = useState(false)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)

    useEffect(()=>{window.scrollTo(0,0)},[])

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                const response = await private_server.get(`/user/all/metrics`)
                setMetrics(response.data.metrics)
                setLoading(false)
                
            } catch (error) {
                console.log(error)
            }
        },

        exportUsers : async () => {
            try{
                setLoading(true)
                const response = await private_server.get('/user/all/export',{
                    responseType: 'blob'
                })
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data], 
                {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
             
                link.href = url;
                let now = new Date()
                let name = 'Users_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDay() + '_' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + '.xlsx'
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false)

            }catch(error){  
                console.log(error)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {metrics}

    return {system, view_data, actions}
}

export default useResumeView