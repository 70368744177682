import React from 'react'
import { CircularProgress, Fab, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetDateFormat, onGetFullname,getCountryText } from '../../../shared/utility'
import { grey } from '@material-ui/core/colors'
import SearchBar from '../../../components/Actions/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/icons/empty.png'
import { catalogs } from '../../../texts/esp/catalogs'
import tableStyles from '../../../styles/tableStyles'
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName'
import GenericStatusEditable from '../../../components/Structure/DisplayData/GenericStatusEditable'



const SimpleTable = props => {

    const classes = tableStyles()
    const {data, task_type, filter, content, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, year, onRemoveItem, onChangeSearch, onAddRegister, 
        onChangeStatuses, onEditMembershipDate,countries} = props
    const header = content.header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' > 
                    <Grid item xs/>
                    <Grid item><SearchBar value={filter.query} onChange={onChangeSearch}/></Grid>         
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' className={classes.header} >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_user, email, user_status_id, image, mobile, first_name, user_type, last_name, enabled, referal_code, coupon, influencer_size, membership_type_id,
                                                username, membership_status_id, membership_start_date,created_at,confirmed_at,updated_at,zone,country_id} = item
                                    
                                    const fullname = onGetFullname(first_name, last_name)
                                    //const membership_type = catalogs.membership_types.find(el => el.value === membership_type_id)
                                    let _zone = '-'
                                    if(zone){
                                        _zone = zone
                                    }
                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{id_user}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName name={fullname} image={image} nopicture={content.nouser}/></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{username}</Typography></TableCell> 
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{email}</Typography></TableCell>
                                            {/* <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{influencer_size}</Typography></TableCell> */}
                                            {/*<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><GenericStatusEditable selected={enabled} options={catalogs.user_statuses} onChange={(val)=>onChangeStatuses('enabled', val, id_user)} color/></TableCell>   */}
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{onGetDateFormat(created_at)}</Typography></TableCell>   
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{onGetDateFormat(confirmed_at)}</Typography></TableCell> 
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{onGetDateFormat(updated_at)}</Typography></TableCell>   

                                            <TableCell className={classes.cell} onClick={() => onEditMembershipDate(item)}><div><Typography variant='body1'>{onGetDateFormat(membership_start_date)}</Typography></div></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body1'>{_zone} / {getCountryText(country_id,countries)}</Typography></TableCell>
                                            {/* <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><RateDisplayer rate={5} /></TableCell> */}

                                            {/*<TableCell className={classes.cell}><IconButton size='small' onClick={() => onRemoveItem(item)}><Icon fontSize='small'>delete</Icon></IconButton></TableCell>*/}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.empty_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>No se encontraron registros</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable