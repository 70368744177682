import { useState, useEffect } from "react"
import { private_server } from "../../config"
import { isFormValid, onGetCurrentUserProfile, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import { catalogs } from "../../texts/esp/catalogs"
import { generic_errors } from "../../texts/esp/genericErrors"



const useSignUpLayout = ({ language, content, history}) => {
    const {REGISTER_STEPS} = content
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [step, setStep] = useState(REGISTER_STEPS.METHOD_SELECTION)
    const [user_type, setUserType] = useState(null)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        actions.onInitModule()
    }, [])
    
    const actions = {
        onInitModule: async() => {
            console.log(content)
            const _form = onInitForm(form, null, {
                ...content.personal_information_section.form,
                ...content.profile_section.form
            })
            _form.gender_id.options = [...catalogs.genders]
            console.log(_form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'password'){
                temp.password_confirmation.value2 = data.value
                temp.password_confirmation.isValid = data.value === temp.password_confirmation.value
            }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeForwardStep:async(_step) => {
            try {
                switch(_step){

                }

                setStep(_step)
            } catch (error) {
                
            }
        },
        
        onSubmit: async() => {

            const errors = isFormValid(form)

            if(errors && errors.length){
                console.log(errors)
                let _form = onSetErrorsToForm(form, errors)
                
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            const data2send = onGetFormData(form)
            console.log(data2send)

            setSending(true)


            setSending(false)

            let temp =  JSON.parse(JSON.stringify(form_data))
            const _form = onInitForm(temp, null,  content.form)
            setForm(_form)
            /* setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch('/users/me', data2send)
                const _user = await onGetCurrentUserProfile()
                onUpdateUserData(_user)

            } catch (error) {
                console.log(error)
            }
            setLoading(false) */
        },
        onGoToView:(_step) => setStep(_step),
        onUpdateUserType: (_data) => {
            setUserType(_data)
            setStep(REGISTER_STEPS.PERSONAL_INFORMATION)
        },
        onChangePage: (url) => history.push(url)
    }

    const system = {loading, sending, error}
    const view_data = {step, user_type, isValid}

    return {system, form, actions, view_data}
}

export default useSignUpLayout

const form_data = {
    birth_date:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'birth_date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    gender_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'gender_id',
            type:'select',
            fullWidth: true,
        },
        rules:{
            type:'date',
        }
    },
    first_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'first_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    last_name:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'last_name',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'email',
            type:'email',
            fullWidth: true,
        },
        rules:{
            type:'email',
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:256
        }
    },
    password_confirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password_confirmation',
            type:'password',
            fullWidth: true,
        },
        rules:{
            type:'equals',
            min:1, max:256
        }
    },
}
