import React, {useState, useRef, useEffect} from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import system_translations from '../../../../../texts/system_translations'
import useProfilePictureModal from './useProfilePictureModal'
import { actionTypes } from '../../../../../store/actions'
import { connect } from 'react-redux'
import RoundedButton from '../../../../../components/Actions/RoundedButton'

const ProfilePictureModal = props => {

    const classes = useStyles()
    const {open, language, onUpdateModalStatus, onUpdateUserData, user, onClose, master_actions} = props

    const {loading, src, crop, actions} = useProfilePictureModal({user, master_actions,onUpdateModalStatus, onUpdateUserData})


    useEffect(() => {
        if(open) actions.onResetComponent()
    }, [open])

    return(
        <SimpleModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Foto de perfil</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={actions.onDropFile} placeholder={'Arrastre o de click para subir una imagen'}/>
                </Grid>
                {src ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' >{'Imagen seleccionada'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.react_drop}>
                                    <ReactCrop 
                                        src={src} crop={crop} 
                                        onImageLoaded={actions.onImageLoaded}
                                        onChange={actions.handleOnCropChange}
                                        onComplete = {actions.handleOnCropComplete}
                                    />
                                </div>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <RoundedButton onClick={actions.onSubmit} loading={loading}>{'Guardar'}</RoundedButton>
                        </Grid>
                    </Grid>
                    {/*<ActionModalBar error={error} loading={loading} btnLabel='Guardar' onDelete={onDelete} onSubmit={onSubmit}/>*/}
                </Grid>
                
            </Grid>
        </SimpleModal>
    )
}

const useStyles = makeStyles(theme => ({
    react_drop:{
        width:'100%',
        height:300,
        overflowY:'auto'
    },
    react_drop_container:{

    }
}))

export default ProfilePictureModal