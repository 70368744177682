import React from 'react'
import { Grid, CircularProgress, IconButton, Icon, Typography } from '@material-ui/core'
import InputForm from '../../../components/Forms/InputForm'
import RoundedButton from '../../../components/Actions/RoundedButton'

const FormView = props => {

    const {form, onChange, isValid, loading, error, onSubmit, history} = props

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            {/* <Grid item>
                                <IconButton size='small' onClick={() => history.goBack()}><Icon fontSize='large'>navigate_before</Icon></IconButton>
                            </Grid> */}
                            <Grid item>
                                <Typography color='primary' variant='h4'>Nueva contraseña</Typography>
                            </Grid>
                        </Grid>         
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='bluegrey' >Por favor ingresa tu nueva contraseña con la que podrás acceder a tu cuenta</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.passwordConfirmation} onChange={onChange}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <RoundedButton fullWidth color='primary' onClick={onSubmit}
                                >Continuar</RoundedButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <div style={{minHeight:44}}>
                                        <Grid container justify='center'>
                                            {loading ? (
                                                <Grid item> <CircularProgress color='secondary'/> </Grid>
                                            ) : null}
                                            {error ? (
                                                <Grid item>
                                                    <Typography color='red'>{error}</Typography>
                                                </Grid>
                                            ):null}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>                           
                        </Grid>
                    </Grid>
                </div>
            </Grid>   
        </Grid>
    )
}

export default FormView