import { Button, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'


//pruebas
import nouser from '../../../../assets/nouser.png'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { onGetDateFormat, onGetFullname } from "../../../../shared/utility"
import { nodata } from "../../../../config"
import moment from "moment"
import StatusData from "../../../../components/Structure/DisplayData/StatusData"
import { catalogs } from "../../../../texts/esp/catalogs"
import { blueGrey } from "@material-ui/core/colors"
import VerticalData from "../../../../components/Structure/DisplayData/VerticalData"
import GenericStatus from "../../../../components/Structure/DisplayData/GenericStatus"

const UserInfo = ({user, onUpdatePassword, onEditUserData, actions}) =>{

    const classes = useStyles()

    let fullname = ''
    let first_name = ''
    let username = ''
    let lastname = ''
    let usergender = '-'
    let status = {}
    let mobile = ''
    let phone = ''
    let email = ''
    let status_id
    let user_type = ''
    let birth_date

    if(user){
        fullname = onGetFullname(user.first_name,user.last_name)
        first_name = user.first_name ? user.first_name : nodata
        lastname = user.last_name ? user.last_name : nodata
        mobile = user.mobile ? user.mobile : nodata
        phone = user.phone ? user.phone : nodata
        username = user.username
        email = user.email
        user_type = user.user_type
        birth_date = onGetDateFormat(user.birth_date)

        status_id = user.enabled
        status = catalogs.user_statuses.find(el => el.value === user.status_id)
        const temp_gender = catalogs.genders.find(el => el.value === user.gender_id)
        if(temp_gender) usergender = temp_gender.label
    }

    return(
        <div>
            
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5} >
                        <SimpleCard>
                            <div className={classes.container1}>
                                <div className={classes.avatar_container} >                              
                                    <Grid container direction="column" alignItems="center" spacing={2}>
                                        <Grid item>
                                        <AvatarPicture size={125} src={user ? user.image : null} nopicture={nouser} 
                                            can_edit onChangePicture={() => actions.onUpdateModal('change_avatar', true)}/>
                                        </Grid>
                                        <Grid item>
                                            <div style={{marginTop:16, marginBottom:0,}}><Typography variant='subtitle1' style={{lineHeight:'normal'}}>{fullname}</Typography></div>
                                            <Typography align="center" variant="body2" color='primary'>{user_type}</Typography>
                                        </Grid>
                                        {/* <Grid item>
                                            <div style={{marginBottom:16}}>
                                            <GenericStatus value={status_id} catalogs={catalogs.user_statuses} />
                                            </div>                       
                                        </Grid> */}                                     
                                        <Grid item>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item><Icon color='primary'>mail</Icon></Grid>
                                                <Grid item><Typography>{user ? user.email : nodata}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1} justifyContent="center">
                                                <Grid item><Icon color='primary'>phone</Icon></Grid>
                                                <Grid item><Typography>{user && user.mobile ? user.mobile : nodata}</Typography></Grid>
                                            </Grid>
                                        </Grid>                                    
                                    </Grid>
                                    <Button className={classes.button} onClick={onUpdatePassword}>Cambiar contraseña</Button>
                                </div>
                            </div>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <SimpleCard>
                            <div className={classes.container2}>
                                <Typography variant='h6' style={{marginBottom:24}}>Información general</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <VerticalData 
                                        label='Nombre(s)'
                                        data={first_name}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalData 
                                        label='Apellido(s)'
                                        data={lastname}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalData 
                                        label='Usuario'
                                        data={username}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' style={{color:'#696969'}} >Estatus</Typography>
                                        <GenericStatus value={status_id} catalogs={catalogs.user_statuses} />
                                    </Grid>      
                                    <Grid item xs={12} md={6}>
                                        <VerticalData 
                                        label='Género'
                                        data={usergender}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <VerticalData 
                                        label='Cumpleaños'
                                        data={birth_date}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent='flex-end'>
                                            <Grid item>
                                                <RoundedButton onClick={onEditUserData} >Editar</RoundedButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </SimpleCard>
                    </Grid>
                </Grid>
            
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    container1:{
        display:'flex',
        justifyContent:'center',
    },
    avatar_container:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding:'20px 16px',
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        //boxShadow:'0px 0px 5px rgba(237, 28, 36, 0.33)',
        borderRadius:20,
        color:theme.palette.primary.dark,
        padding:'10px 20px',
        textTransform:'none',
        marginTop:20
    },
    name:{
        marginTop:20
    },
    container2:{
        padding:20,
        [theme.breakpoints.down('sm')]:{
            padding:8,
        }
    },
    topbar:{
        marginBottom:20
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        //marginRight:16
    }
    
}))

export default UserInfo

const TitleText = (props) => {
    return(
        <Typography variant='body2' style={{color:'#909090'}}>{props.children}</Typography>
    )
}