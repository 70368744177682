import { Grid, Icon, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { nodata } from "../../../config";
import StatusData from "./StatusData";

const GenericStatus = ({value, bold, variant, catalogs, text_styles}) => {

    const status = catalogs.find(el=>el.value === value)

    return ( 
        <StatusData data={status ? status.label : nodata} color={status ? status.color : grey[300]} text_styles={text_styles}/>
    );
}

export default GenericStatus