import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import SignInLayout from '../layouts/SignInLayout/SignInLayout';
import SignUpLayout from '../layouts/SignUpLayout/SignUpLayout';
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import ResetPasswordView from '../layouts/ResetPasswordView/ResetPasswordView';

const MainRouter = ({ history }) => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={'/signin'} component={SignInLayout} />
                <Route path={'/signup'} component={SignUpLayout} />
                <Route path='/passwordchange' component={ResetPasswordView}  />
                <Route path={'/'} component={WebsiteLayout} />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default MainRouter