import { Button, Dialog, Grid, Grow, Icon, IconButton, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5),
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            padding:theme.spacing(3),
            margin:8
        },
        
    },
    root:{
        
        //padding:40
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    },
    close_button:{
        position:'absolute', right:16, top:16
    }
}))

const SimpleModal = (props) => {

    const classes = useStyles()
    const {open, children, onClose, maxWidth, fullWidth} = props

    return(
        <Dialog open={open} maxWidth={maxWidth ? maxWidth : 'xs'} fullWidth={fullWidth !== undefined ? fullWidth : true} classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition} onClose={undefined} scroll='body'>
            <div className={classes.root}>
                <IconButton className={classes.close_button} onClick={onClose} ><Icon>close</Icon></IconButton>
                {children}
            </div>
        </Dialog>
    )
}

export default SimpleModal
