import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Switch, Typography } from '@material-ui/core'
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetFullname, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../shared/utility'
import InputForm from '../../../components/Forms/InputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { private_server } from '../../../config'
import { modalStyles } from '../../../styles/modalStyles'
import { Rating } from '@material-ui/lab';
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName'


const EditReviewModal = props => {

    const { open, onClose, origin, onChangeApproved  } = props

    const classes = styles()

    const [approved, setApproved] = useState(false)

    useEffect(() => {
        if (open && origin) {
            setApproved(Boolean(origin.approved))
        }
    }, [open])
    
    const onSwitchApproved = (event, id) => {
        console.log(event.target.checked)
        setApproved(event.target.checked)
        let appr = event.target.checked ? 1 : 0
        onChangeApproved(id, appr)
    }

    let name = ''

    if(origin) name = onGetFullname(origin.user_first_name, origin.user_last_name)

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div className={classes.header}>
                <Grid container spacing={3} justifyContent='center'  alignItems='center'>
                    <Grid item xs={12}>
                        <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                            <UserAvatarName name={name} image={origin ? origin.user_image : null} />
                            <div style={{display:'block'}}>
                                <Typography variant='caption' color='textSecondary'>Aprobado</Typography>
                                <Switch checked={approved} onChange={(e)=>onSwitchApproved(e, origin ? origin.id_review : null)} 
                                inputProps={{ 'aria-label': 'controlled' }} color='primary'  />
                            </div>
                        </div>
                    </Grid>
                    <Grid item   >
                        <Typography variant='subtitle2' style={{lineHeight:1}}>
                            Tiempo de respuesta
                        </Typography>
                        <Rating  value={origin ? origin.response_time : 0} readOnly /> 
                    </Grid>
                    <Grid item   >
                        <Typography variant='subtitle2' style={{lineHeight:1}}>
                            Profesionalismo
                        </Typography>
                        <Rating  value={origin ? origin.professionalism : 0} readOnly /> 
                    </Grid>
                    <Grid item   >
                        <Typography variant='subtitle2' style={{lineHeight:1}}>
                            Calidad/Precio
                        </Typography>
                        <Rating  value={origin ? origin.price_quality : 0} readOnly /> 
                    </Grid>
                    <Grid item   >
                        <Typography variant='subtitle2' style={{lineHeight:1}}>
                            Soporte al cliente
                        </Typography>
                        <Rating  value={origin ? origin.customer_support : 0} readOnly /> 
                    </Grid>
                    <Grid item   >
                        <Typography variant='subtitle2' style={{lineHeight:1}}>
                            Satisfacción general
                        </Typography>
                        <Rating  value={origin ? origin.general_satisfaction : 0} readOnly /> 
                    </Grid>
                </Grid>
            </div>
            <div className={classes.comment_container}>
                <Typography variant='subtitle2' style={{lineHeight:1, marginBottom:8}}>
                    Comentarios
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                    {origin ? origin.email : null} - {origin ? onGetDateFormat(origin.date) : null}
                </Typography> 
                <Typography variant='body2'>
                    {origin ? origin.comment : ''}
                </Typography>
            </div>
        </SimpleModal>
    )
}

export default EditReviewModal


const styles = makeStyles(theme=>({
    header:{
        marginTop:24
    },
    comment_container:{
        marginTop:32
    }
}))