import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { isValidDocument } from '../../../shared/utility'
import { CONFIG_SETTINGS } from '../../../config';



const useStyles = makeStyles(theme => ({
    avatar:{
        '& .MuiAvatar-img':{
            objectFit:'contain',

        },
        border:'1px solid #BFBFBE',
        
    },
    noimage:{
        '& .MuiAvatar-img':{
            objectFit:'contain',
            //width:20,
            //height:20
        },
        //padding:6,
        boxSizing:'border-box',
        border:'1px solid #BFBFBE'
    }
}))

const UserAvatarName = props => {

    const classes = useStyles()
    const {image, name, variant, nopicture} = props
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture

    return(
        <div>
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                <Grid item>
                    {isValid ? <Avatar src={selectedImage} alt='' className={classes.avatar}/> : 
                    <Avatar src={selectedImage} alt='' className={classes.noimage}/>}
                </Grid>
                <Grid item>
                    <Typography variant={variant ? variant : 'body1'} >{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserAvatarName