import moment from "moment"
import { useEffect, useState } from "react"
import {  isFormValid, onGetErrorMessage, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import { public_server } from "../../config"
import queryString from 'query-string';


const useResetPasswordView = ({history}) => {
    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const {token} = queryString.parse(history.location.search)

    //const token = match.params.token


    /* useEffect(() => {
        if(user) actions.onInitModule()
    }, [user]) */

    const actions = {
        onInitModule: async() => {
            
        },
        onChange:(data) => {
            let temp = {...form}
            const id = data.config.id
            temp[id] = {...data}
            if(id === 'password'){
                temp.passwordConfirmation.value2 = temp[id].value
            }
            setForm(temp)
            //setIsValid(isFormValid(temp))
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            let data2send = {
                token:token,
                password: form.password.value
            }
            console.log(data2send)
            console.log(token)
    
            setLoading(true)
            try {
                updateAuthorizationHeader(public_server)
                await public_server.post(`/user/passwordchange/validate`, {token:token})
                await public_server.post(`/user/passwordchange/execute`, data2send)
                setIsPasswordRecover(true)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
            
        },
        onClearError: () => setError(null)

    }

    const system = {loading, error}
  

    return {system, isPasswordRecover, form, actions}
}

export default useResetPasswordView

const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula'
        },
        rules:{
          type:'distance',
          min:6
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helperText:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}