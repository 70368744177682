import logo from '../../assets/logo.png'

// Social media icons
import facebook_icon from '../../assets/icons/facebook.png'
import instagram_icon from '../../assets/icons/instagram.png'
import twitter_icon from '../../assets/icons/twitter.png'

import manager_icon from '../../assets/icons/manager.svg'
import blog_icon from '../../assets/icons/blog.svg'
import influencer_icon from '../../assets/icons/influencer.svg'
import user_icon from '../../assets/icons/user.svg'


const general_translations = {
    sidebar:{
        logo:logo,
        title:'Monitoring System',
        list:[
            {
                name:'Resumen',
                path:'/resume',
                icon: 'person',
                image:user_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Usuarios',
                path:'/users',
                icon:'person',
                image:user_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Influencers',
                path:'/influencers',
                icon:'device_hub',
                image:influencer_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Managers',
                path:'/managers',
                icon:'device_hub',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Marcas',
                path:'/brands',
                icon:'device_hub',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Agencias',
                path:'/agencies',
                icon:'device_hub',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Categorias',
                path:'/categories',
                icon:'category',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Comentarios',
                path:'/reviews',
                icon:'category',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
            {
                name:'Eliminados',
                path:'/deleted',
                icon:'device_hub',
                image:manager_icon,
                min_level:1,
                max_level:1
            },
        ]
    },
    topbar:{
        titles:[
            {
                url:'/',
                title:'InPager'
            },
            {
                url:'/',
                title:'Únete ahora'
            },
            {
                url:'/',
                title:'Inicia sesión'
            },
        ],
        cart_button:{
            remove:'Eliminar',
            subtotal:'Subtotal',
            view_button:'Ver carrito',
            pay_button:'Pagar'
        },
        user_button:{
            nouser:[
                {id:1, label:'Iniciar sesión', url:'/signin'},
                {id:2, label:'Regístrate', url:'/signup'},
                {id:3, label:'Hazte un celudoc', url:'/', top_line:true},
                {id:4, label:'Nuestras membresías', url:'/'},
                {id:5, label:'Ayuda', url:'/', top_line:true},
                {id:6, label:'Contáctanos', url:'/'},
            ],
            user:[
                {id:5, label:'Mi perfil', icon:'person', url:'/profile'},
                {id:9, label:'Cerrar sesión', icon:'logout', url:'/logout', top_line:true},
            ]
        },
        searchbar_menu:[
            {id:1, label:'Celufarma'},
            {id:2, label:'Celudoc'},
            {id:3, label:'Celuhistoria'},
        ],
    },
    footer:{
        links:[
            {id:1, label:'Agencias', link:'/'},
            {id:2, label:'Influencers', link:'/'},
            {id:3, label:'Prensa', link:'/'},
            {id:4, label:'Marcas', link:'/'},
        ],
        contact_links:[
            {id:1, label:'Contacto', link:'/'},
            {id:2, label:'Acerca de', link:'/'},
            {id:3, label:'Servicios', link:'/'},
        ],
        legal_links:[
            {id:1, label:`Terminos de uso`, url:'/privacy', target:'_blank'},
            {id:2, label:'Aviso de privacidad', url:'/terms-conditions', target:'_blank'},
            {id:3, label:'Ayuda', url:'/', target:'_blank'},
        ],
        social_media:{
            title:'InPager',
            media:[
                {id:1, icon:facebook_icon, url:'/', target:'_blank'},
                {id:2, icon:instagram_icon, url:'/', target:'_blank'},
                {id:3, icon:twitter_icon, url:'/', target:'_blank'},
            ],
        }
    },
    
}

export default general_translations

