import React, {useState, useEffect} from 'react';
import { alpha, AppBar, Button, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { general_translations as translations_esp } from '../../../texts/esp/general-translations'

import cx from 'classnames'
import { blue, grey } from '@material-ui/core/colors';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { onClearMemory } from '../../../shared/utility';
import UserButton from '../../../components/Actions/UserButton';

const Topbar = ({user, content, history, is_auth, cart, onUpdateSignInModal, onUpdateUserData, onUpdateAuthStatus, onUpdateDrawerStatus, drawer_status}) => {

    const classes = useStyles()
    //const content = translations_esp.topbar
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})


    const onItemSelected = async(url) => {
        console.log(url)
        switch (url) {
            case '/signin':
                onUpdateSignInModal(true)
                break;
            case '/logout':
                onUpdateUserData(null)
                onUpdateAuthStatus(false)
                onClearMemory()
                history.push('/signin')
                break;
        
            default:
                history.push(url)
                break;
        }
    }

    return ( 
        <AppBar position='fixed' className={cx({
            [classes.appbar]:true,
            [classes.appbarShift]:drawer_status && !ismobile
        })}>
            <div className={classes.root}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                       <IconButton color="primary" onClick={() => onUpdateDrawerStatus(!drawer_status)}>
                           <Icon>menu</Icon>
                       </IconButton>
                    </Grid>
                    <Grid item xs></Grid>
                    {/* <Grid item>
                        <a style={{textDecoration:'none', color:'inherit'}} href='https://amiif-dev-public.s3.amazonaws.com/businessdocuments/manualdeusuario.pdf' target='_blank'>
                            <div className={classes.file_button}>
                                <Icon style={{marginRight:4}}>description</Icon>
                                Manual
                            </div>
                        </a>
                    </Grid> */}
                    <Grid item>
                        <UserButton ismobile={ismobile} user={user} content={content.user_button} onItemSelected={onItemSelected} />
                    </Grid>
                </Grid>
            </div>
        </AppBar>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        //width:1400,
        //marginLeft:DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        padding:'12px 24px',
        /* margin:'auto',
        boxSizing:'border-box',
        position:'relative',
        width:`calc(100% - ${DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH}px )`,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'0px 16px'
        }, */
    },
    button:{
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        textTransform:'none',
        padding:'8px 16px',
        borderRadius:32,
        fontWeight:700
    },
    appbar:{
        //background:alpha("#FFF",0.62),
        background:'#fcfcfc',
        boxShadow:'none',
        //background:grey[400],
        //background:'transparent',
        color:'black',
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        height:76,
        //borderBottom:'2px solid #EBEBEB'
        //color:'grey',
    },
    appbarShift:{
        color:'black',
        width: `calc(100% - ${DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH}px)`,
        marginLeft: DASHBOARD_SETTINGS.PROFILE_DRAWER.WIDTH,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appbar_mono:{
        //background:'transparent',
        
    },
    logo:{
        width:48
    },
    file_button:{
        display:'flex',
        alignItems:'center',
        fontFamily:theme.typography.fontFamily,
        background:'rgb(13, 71, 161)',
        color:'white',
        padding:'8px 16px',
        boxSizing:'border-box',
        borderRadius:10,
        fontSize:14,
        textTransform:'uppercase',
        fontWeight:600,
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        '&:hover':{
            background:'rgb(10, 53, 120)',
        }
    }
}))

const mapStateToProps = state => {
    return {
        user:state.user,
        is_auth:state.is_auth,
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSignInModal: (signin_modal) => dispatch({type:actionTypes.MODAL_UPDATE_SIGNIN, signin_modal}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})

    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));
