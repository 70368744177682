import React from 'react';
import cx from 'classnames'
import { makeStyles } from '@material-ui/core';

const SectionContainer = ({classes, children}) => {

    const _classes = useStyles()

    return ( 
        <div className={cx(_classes.root, classes ? classes.root : null)}>
            {children}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        margin:'auto',
        width:1800,
        padding:'0px 80px',
        boxSizing:'border-box',
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            margin:0,
        },
        [theme.breakpoints.down('md')]:{
            padding:'0px 40px',
        },
        [theme.breakpoints.down('xs')]:{
            padding:'0px 16px',
        }
    }
}))
 
export default SectionContainer;