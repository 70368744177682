import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Categorías',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Url'},
            {id:4, label:'Descripción'},
            {id:5, label:''},
        ],
        nodata:'No existen categorias registradas',
        nouser:nouser
    }
    
}

export default translations