import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import sigin_layout_es from './esp/signin-translations'
import sigup_layout_es from './esp/signup-translations'
import users_es from './esp/users-translations'
import influencers_es from './esp/influencers-translations'
import managers_es from './esp/managers-translations'
import categories_es from './esp/categories-translations'
import reviews_es from './esp/reviews-translations'
import brands_es from './esp/brands-translations'
import agencies_es from './esp/agencies-translations'
import resume_es from './esp/resume-translations'
import deleted_es from './esp/deleted-transaltions'

const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        layouts:{
            signin: sigin_layout_es,
            signup: sigup_layout_es
        },
        views:{
            users:users_es,
            influencers:influencers_es,
            managers:managers_es,
            categories:categories_es,
            reviews:reviews_es,
            brands:brands_es,
            agencies:agencies_es,
            resume:resume_es,
            deleted:deleted_es
        },
        modals:{

        },
    },
    eng:{
        
    }
}

export default system_translations