import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import { private_server } from '../../../../../config'


const PasswordModal = props => {

    const { open, onClose, origin, actions, onRequestUsers, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['confirm_password'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.confirm_password
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            const user_id = origin.id_user
            await private_server.patch(`/user/${origin.id_user}`, data2send)
            await actions.onRefreshData()
            actions.onUpdateModal('change_password', false)
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h6' style={{ fontWeight: 600 }}>Cambio de contraseña</Typography>
                <Typography variant='body1' color='textSecondary'>Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.confirm_password} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div style={{width:'100%', textAlign:'center'}}>
                <RoundedButton color='primary'  onClick={onSubmit} loading={loading}>
                    Actualizar
                </RoundedButton>
                <Typography style={{marginTop:12}} color='error' align='center'>{error}</Typography>
                </div>
            </div>
        </SimpleModal>
    )
}

export default PasswordModal


const formData = {
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Nueva Contraseña',
            helperText: 'Debe contener 8 a 64 caracteres',
            //autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min:8, max:64
        }
    },
    confirm_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
            label: 'Confirmar contraseña',
            helperText: 'La contraseña no coincide'
        },
        rules: {
            type: 'equals',
            //min:6, max:20
        }
    },
}