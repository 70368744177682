import React, { useState, useEffect } from 'react';
import { alpha, Button, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

const OverviewSection = ({content, form, history, actions, view_data}) => {

    const classes = useStyles();

    const {REGISTER_STEPS} = content
    const {user_type} = view_data
    const _content = content.overview_section


    const valid =   form.first_name.isValid && form.last_name.isValid &&
                    form.email.isValid && form.username.isValid &&
                    form.password.isValid && form.password_confirmation.isValid 

    
    return ( 
        <div>
            <div className={classes.content_data}>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs>
                        <Typography variant='h4' style={{fontWeight:800, fontSize:36}}>
                            {_content.title}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.form}>
                    <Grid container spacing={3} direction='column' alignItems='center'>
                        
                        <Grid item>
                            <Typography variant='h6' align='center' style={{fontWeight:600, width:300, fontSize:24}}>
                                {_content.message}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' align='center' style={{fontWeight:400, fontSize:18}}>
                                {`${_content.submessage}`} <strong>{form.email.value}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Button className={classes.submit_button} fullWidth 
                    onClick={() => actions.onChangePage('/signin')} >{_content.next_button}</Button>
                </div>
                
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    content_data:{
        marginTop:140,
    },
    form:{
        
        margin:'60px auto',
        //width:400,
        //paddingRight:24,
        boxSizing:'border-box',
    },
    card:{
        width:'100%',
        paddingTop:'118%',
        border:`2px solid ${grey[300]}`,
        borderRadius:9,
        cursor:'pointer',
        transition:'all 0.1s linear',
        fontFamily:theme.typography.fontFamily,
        fontWeight:600,
        '&:hover':{
            transition:'all 0.4s linear',
            background:alpha("#000",0.01),
            border:`2px solid ${theme.palette.primary.main}`,
        },
        position:'relative'
    },
    card_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main
        //background:'red'
    },
    card_content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    return_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        }
    },
    label:{
        color:'#6B6B6B',
        fontSize:18,
        fontWeight:700,
        marginBottom:12
    },
    submit_button:{
        fontSize:18,
        textTransform:'none',
        background:theme.palette.primary.main,
        color:'white',
        fontWeight:600,
        padding:12,
        borderRadius:40,
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:disabled':{
            background:grey[300]
        }
    },
}))
 
export default OverviewSection;