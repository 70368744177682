import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const tableStyles = makeStyles(theme => ({
    paper:{
        borderRadius:16,
        boxShadow:'0px 7px 19px rgba(0, 0, 0, 0.1)',
    },
    cell:{
        borderColor:grey[200],
    },
    header:{
        fontSize:14,
        color:'#A4A4A4', 
        fontWeight:600
    },
    row:{
        
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
        borderBottom:'5px solid #F2F2F2'
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16,
        //border:'1px solid red'
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    empty:{
        width:180
    },
    empty_container:{
        
    },
    add_button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
}))

export default tableStyles