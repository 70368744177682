import { makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import system_translations from "../../../texts/system_translations";
import UserInfo from "./components/UserInfo"
import useProfileView from './useProfileView'
import PasswordModal from './modals/PasswordModal/PasswordModal';
import EditUserModal from './modals/EditUserModal/EditUserModal';
import Page from "../../../components/Structure/Layouts/Page";
import ProfilePictureModal from "./modals/ProfilePictureModal/ProfilePictureModal";
import { actionTypes } from "../../../store/actions";


const ProfileView = ({language, history, user, match, onUpdateUserData}) => {

    const classes = useStyles()
    const content = system_translations[language].views.user

    const {view_data, actions, modals, system} = useProfileView({history, user, match, onUpdateUserData})




    return(
        <Page title={"Mi perfil"} setReturn loading={system.loading} >   
            <ProfilePictureModal open={modals.change_avatar} master_actions={actions} 
            user={view_data.profile_view ? user : view_data.selected_user}
            onClose={() => actions.onUpdateModal('change_avatar',false)} />
            <PasswordModal origin={view_data.selected_user} open={modals.change_password} history={history} actions={actions} view_data={view_data}
            onClose={() => actions.onUpdateModal('change_password',false)} />
            <EditUserModal origin={view_data.selected_user} open={modals.edit_user} history={history} actions={actions} view_data={view_data}
            onClose={() => actions.onUpdateModal('edit_user', false)} />
            <UserInfo user={view_data.selected_user} actions={actions}
                onUpdatePassword={() => {
                    actions.onUpdateModal('change_password',true)
                }}
                onEditUserData={(item) => {
                    actions.onUpdateModal('edit_user', true)
                }}
            
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language:state.language, 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView)

const useStyles = makeStyles(theme => ({
    edit_button:{
        background:theme.palette.primary.main,
        borderRadius:20,
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.31)',
        textTransform:'none',
        color:'white',
        padding:'8px 28px'
    },
    delete_button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)'
    }
    
}))