import React, { useState, useEffect } from 'react';
import { alpha, Button, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import cx from 'classnames'
import { grey } from '@material-ui/core/colors';

const UserTypeSection = ({content, history, actions, view_data}) => {

    const classes = useStyles();

    const {REGISTER_STEPS} = content
    const {user_type} = view_data
    const _content = content.user_type_section


    return ( 
        <div>
            <div className={classes.content_data}>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <IconButton size='small' className={classes.return_button} onClick={() => actions.onGoToView(REGISTER_STEPS.METHOD_SELECTION)}
                        ><Icon fontSize='large'>arrow_back_ios_new</Icon></IconButton>
                    </Grid> 
                    <Grid item xs>
                        <Typography variant='h4' style={{fontWeight:800}}>
                            {_content.title}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.form}>
                    <Grid container spacing={4}>
                        {_content.user_types.map(item=>{
                            //console.log(item)

                            const selected = item.id === user_type

                            return(
                                <Grid item xs={12} md={6} key={item.id.toString()}>
                                    <div className={cx({
                                        [classes.card]:true,
                                        [classes.card_selected]:selected,
                                        [classes.card_disabled]:!item.available
                                    })} onClick={item.available ? () => actions.onUpdateUserType(item.id) : undefined}>
                                        <div className={classes.card_content}>
                                            <Grid container alignItems='center' direction='column' spacing={1}>
                                                <Grid item>
                                                    <SVGIcon src={item.icon} size={36} color={selected ? '#843BA8' : '#363636'}/>
                                                </Grid>
                                                <Grid item>
                                                    {item.label}
                                                </Grid>
                                                {!item.available ? (
                                                    <Grid item>
                                                        <Typography variant='caption'>Próximamente</Typography>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </div>   
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    content_data:{
        marginTop:140,
    },
    form:{
        
        margin:'60px auto',
        width:400,
        paddingRight:24,
        boxSizing:'border-box',
    },
    card:{
        width:'100%',
        paddingTop:'118%',
        border:`2px solid ${grey[300]}`,
        borderRadius:9,
        cursor:'pointer',
        transition:'all 0.1s linear',
        fontFamily:theme.typography.fontFamily,
        fontWeight:600,
        '&:hover':{
            transition:'all 0.4s linear',
            background:alpha("#000",0.01),
            border:`2px solid ${theme.palette.primary.main}`,
        },
        position:'relative'
    },
    card_selected:{
        border:`2px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main
        //background:'red'
    },
    card_content:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    return_button:{
        color:theme.palette.primary.main,
        '&:hover':{
            background:'none'
        }
    },
    card_disabled:{
        opacity:0.7,
        '&:hover':{
            transition:'all 0.4s linear',
            border:`2px solid ${grey[300]}`,
        },
    }
}))
 
export default UserTypeSection;