import React, {useState, useEffect} from 'react'
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { updateAuthorizationHeader } from '../../../../shared/utility'
import { private_server } from '../../../../config'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import ActionModalBar from '../../../../components/Actions/ActionModalBar'

const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}))

const DeleteUserModal = props => {

    const classes = useStyles()
    const {open, onClose, origin, actions} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError(false)
        setLoading(false)
        console.log("open")
    }, [open])

    const onSubmit = async() => {
        const {id_user} = origin
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/user/${id_user}`)
            await actions.onUpdateUsers()
            await actions.onUpdateModalStatus('delete_register', false)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }

    return(
        <SimpleModal open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6'  align='center'>¿Estás seguro?</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1' align='center'> ¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar loading={loading} error={error} btnLabel='Eliminar' btnColor='red' onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default DeleteUserModal