import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/Page';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';
import SimpleTable from './components/SimpleTable';
import AddInfluencerModal from './modals/AddInfluencerModal/AddInfluencerModal';
import EditMembershipDateModal from './modals/EditMembershipDateModal';
import useInfluencersView from './useInfluencersView';


const InfluencersView = ({user, history, language,countries}) => {

    const content = system_translations[language].views.influencers
    const {system, view_data, actions, modals } = useInfluencersView({user})

    return ( 
        <div style={{minHeight:'100vh'}}>
            <Page title={content.title} >
                <AddInfluencerModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions} managers={view_data.managers}
                    onClose={() => actions.onUpdateModalStatus('add_register', false)} user={user} /> 
                <EditMembershipDateModal open={modals.edit_membership_date} onClose={()=>actions.onUpdateModalStatus('edit_membership_date', false)} 
                    origin={view_data.selectedItem} actions={actions} />
                <div>
                    <SimpleTable 
                        countries = {countries}
                        content={content.table}
                        loading={system.loading || system.sending}
                        data={view_data.data} 
                        filter={view_data.filter}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total} 
                        onDeleteUser={actions.onDeleteUser}
                        onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                        onUpdateTableFilter={actions.onUpdateTableFilter}
                        onChangeFilter={actions.onChangeFilter} 
                        onChangeSearch={actions.onChangeSearch}
                        onChangeStatuses={actions.onChangeInfluencerStatuses}
                        onEditMembershipDate={actions.onEditMembershipDate}
                        />
                </div>
            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        countries:state.countries,
        language:state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(InfluencersView);