import nouser from '../../assets/nouser.png'

export const translations = {
    title:'Managers',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Usuario'},
            {id:4, label:'Correo electrónico'},
            {id:5,label:'Fecha de registro'},
            {id:6,label:'Fecha de verificación'}, 
            {id:7,label:'Fecha de eliminación de perfil'},
            {id:8, label:'Membresía'},
            {id:9,label:'Ciudad / País'}
        ],
        nodata:'No existen usuarios registrados',
        nouser:nouser
    }
    
}

export default translations