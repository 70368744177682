import { useState, useEffect, useRef } from "react"
import {  TOKEN_STATUSES } from "../../config"
import { onClearTokenSession, onGetCurrentUserProfile, onGetPrivateCurrentCart, onGetTokenFromMemory, onReAuthToken, onValidateToken,onGetCountries } from "../../shared/utility"

const useWebsiteLayout = ({onUpdateUserData, onUpdateAuthStatus, onUpdateCart,onUpdateCountries}) => {
    const [loading, setLoading] = useState(true)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        actions.onInitModule()
    },[])

    useInterval(() => {
        //console.log('Revisar')
        actions.onCheckTokenExpirationDate()
    }, 3000)

    const actions = {
        onInitModule: async() => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                let _user
                let _countries
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        console.log('Expirado o no valido')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        _countries = await onGetCountries()
                        onUpdateCountries(_countries)
                        onUpdateAuthStatus(true)
                        break;
                    default:
                        console.log('Valido ')
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        _countries = await onGetCountries()
                        onUpdateCountries(_countries)
                        onUpdateAuthStatus(true)
                        break;
                }             
                
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onCheckTokenExpirationDate: async() => {
            console.log('Revisar')
            if(busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }                
            } catch (error) {
                console.log(error)
            }
        }
    }

    return {loading, sending, error, actions}
}

export default useWebsiteLayout;

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}
