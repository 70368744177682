import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from '../../../shared/utility'
import InputForm from '../../../components/Forms/InputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { private_server } from '../../../config'
import { modalStyles } from '../../../styles/modalStyles'


const AddCategoryModal = props => {

    const { open, onClose, onCompleted } = props

    const classes = modalStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            console.log(errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        

        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            console.log(data2send)
            await private_server.post('/category', data2send)
            await onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
        }
        setLoading(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div >
                <Typography variant='h6'  style={{ fontWeight: 600 }}>Agregar categoría</Typography>
                <Typography variant='body1' color='textSecondary' >Por favor ingresa los datos solicitados</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={loading}>
                    Agregar
                </RoundedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default AddCategoryModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    
}