import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/Page';
import { actionTypes } from '../../../store/actions';
import SimpleTable from './components/SimpleTable';
import AddUserModal from './modals/AddUserModal/AddUserModal';
import useAdministratorsView from './useAdministratorViews';


const AdministratorsView = ({user, history}) => {

    //const content = translations_esp
    const {system, view_data, actions, modals } = useAdministratorsView()

    return ( 
        <div style={{minHeight:'100vh'}}>
            <Page title='Administradores'>
                <AddUserModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions}
                onClose={() => actions.onUpdateModalStatus('add_register', false)}/>
                <div>
                    <SimpleTable 
                        loading={system.loading || system.sending}
                        data={view_data.data} 
                        filter={view_data.filter}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total} 
                        loading={system.loading}
                        onSelectedItem={(item) => history.push(`/admins/${item.id_user}`)}
                        onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                        onUpdateTableFilter={actions.onUpdateTableFilter}
                        onChangeFilter={actions.onChangeFilter} />
                </div>
            </Page>
        </div>  
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AdministratorsView);